// import CryptoJS from "crypto-js";
import { setAuthData, setLoginStorageData, setOTPData, setToken } from "./storage";

export function encryptStorage(param: any, flag: number) {
  const newParam = JSON.stringify(param);
  // const encrypt = CryptoJS.AES.encrypt(
  //   newParam,
  //   process.env.NEXT_PUBLIC_PROJECT_ENCRYPT as string,
  // ) as unknown as string;
  switch (flag) {
    case 0:
      setToken(String(newParam));
      break;
    case 1:
      setLoginStorageData(String(newParam));
      break;
    case 2:
      setAuthData(String(newParam));
      break;
    case 3:
      setOTPData(String(newParam));
      break;
  }
}

export function decrypTokenStorage(param: string) {
  return param;
  // const decrypt = CryptoJS.AES.decrypt(param, process.env.NEXT_PUBLIC_PROJECT_ENCRYPT as string);
  // return decrypt.toString(CryptoJS.enc.Utf8);
}
